import React, { useEffect, useState, useCallback, createContext, useMemo, useContext } from 'react'
import { ConfirmDialog, Dialog, GhostPrimaryButton, Modal, PromptDialog, useToast } from '@bonitour/components'
import { useTransactions } from './useTransactions'
import { useFinanceDeletePrompt } from 'containers/Reservation/Finance/useFinanceDeletePrompt'
import { head } from '@bonitour/common-functions'
import { useTransactionForm } from './useTransactionForm'
import { CREATE_LINK_MULTIPONTO_STATE, CREATE_LINK_PIX_STATE, CREATE_LINK_STATE, CREATE_PAYMENT_STATE, CREATE_REFUND_SPLIT_STATE, CREATE_REFUND_STATE, EDIT_PAYMENT_STATE, EDIT_REFUND_STATE } from '../Transactions.states'
import { fiscalDutyDialog } from 'domains/Reservation/Show/Tickets/TicketSummary'
import { OverpaymentDetails } from 'containers/Reservation/Finance/Finance'
import { overpayingDialogCss } from 'containers/Reservation/Payment/DetailTicketList.style'
import { ReservationPaymentDetail } from 'containers/Reservation/Payment/Detail'
import { useFeatureFlag } from 'contexts/Feature'
import { TransactionModal } from '../TransactionModal'
import { usePermission } from 'contexts/Permissions'
import { usePaymentsTotalizers } from './usePaymentsTotalizers'

const FinanceActionsContext = createContext({})

export const useFinanceActions = () => {
  const context = useContext(FinanceActionsContext)
  if (!context) {
    throw new Error('useFinanceActions must be used within a FinanceActionsProvider')
  }
  return context
}

export const FinanceActionsProvider = ({ children, getSubordinates, hasFiscalDuty }) => {
  const { add: addToast } = useToast()
  const { REACT_APP_ALLY_URL, REACT_APP_CHECKOUT_URL, REACT_APP_PAY_URL } = process.env

  const [allowTicketOverpayment] = useFeatureFlag('allow-ticket-overpayment')

  const {
    amount,
    createRefund,
    createRefundPay,
    removeRefund,
    getRefundById,
    getRefundPaymentLinkById,
    createPayment,
    createLinkPayment,
    createLinkPaymentMultiponto,
    createLinkPaymentPix,
    expirePaymentLink,
    changePayment,
    getPaymentById,
    getPaymentLinkById,
    removePayment,
    changeRefund,
    getTicketsToRefund,
    isLinkCreateLoading
  } = useTransactions()

  const { totalizers: transactionsTotalizers, refetchTotalizers } = usePaymentsTotalizers()

  const {
    view,
    tickets = [],
    transactionFormData,
    openTransactionForm,
    closeTransactionForm,
    formSchema,
    onTransactionSubmit,
    loading,
    transactionFormLoading
  } = useTransactionForm(createPayment, createLinkPayment, createLinkPaymentMultiponto, createLinkPaymentPix, createRefund, createRefundPay, changePayment, changeRefund)

  const onRemovePayment = useCallback((paymentId, reasonDelete) => {
    return removePayment(paymentId, reasonDelete).then(() => {
      refetchTotalizers()
    })
  }, [refetchTotalizers, removePayment])

  const onRemoveRefund = useCallback((refundId, reasonDelete) => {
    return removeRefund(refundId, reasonDelete).then(() => {
      refetchTotalizers()
    })
  }, [refetchTotalizers, removeRefund])

  const [isPaymentVisible, setPaymentVisibility] = useState(false)
  const [isTransactionVisible, setTransactionVisibility] = useState(false)
  const [payment, setPayment] = useState({})
  const [isPayment, setIsPayment] = useState(false)
  const [isPaymentLink, setPaymentLink] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { openDialog: openDeleteDialog, promptProps } = useFinanceDeletePrompt({ removePayment: onRemovePayment, removeRefund: onRemoveRefund })
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)
  const [paymentLabels, setPaymentLabels] = useState({ detailTitle: 'Visualizar pagamento', amountDueLabel: 'Valor pago' })
  const [ticketsIds, setTicketsIds] = useState([])
  const [totalValue, setTotalValue] = useState(0)
  const { isRefund = false } = view || {}
  const isPaymentEdit = view?.title === 'Editar pagamento'
  const isRefundEdit = view?.title === 'Editar reembolso'
  const isPaymentShow = paymentLabels?.detailTitle === 'Visualizar pagamento'

  const paymentTotalValue = useMemo(() => {
    return ticketsIds.reduce((acc, { id, amount, ticketPaymentBalance }) => {
      const { alreadyPaidTicketValue } = head(ticketPaymentBalance) || {}
      if (tickets.map(ticket => ticket.id).indexOf(id) === -1) {
        return acc
      }
      const operationValue = amount > 0 ? amount : (isRefund ? alreadyPaidTicketValue : 0)
      return acc + operationValue
    }, 0)
  }, [tickets, ticketsIds, isRefund])

  const onPaymentLinkShow = useCallback(async (id) => {
    try {
      const paymentData = await getPaymentLinkById(id)
      setPaymentLabels({ detailTitle: 'Visualizar link de pagamento', amountDueLabel: 'Valor devido' })
      setPayment(paymentData)
      setPaymentVisibility(true)
      setPaymentLink(true)
    } catch (error) {
      addToast('Erro ao buscar detalhes do link de pagamento')
    }
  }, [addToast, getPaymentLinkById])

  const onPaymentShow = useCallback(async (id, isRefund) => {
    if (!isRefund) {
      try {
        const paymentData = await getPaymentById(id)
        setPayment(paymentData)
        setPaymentLabels({ detailTitle: 'Visualizar pagamento', amountDueLabel: 'Valor pago' })
        setPaymentVisibility(true)
        setIsPayment(true)
      } catch (error) {
        addToast('Erro ao buscar detalhes do pagamento')
      }
    } else {
      try {
        const refundData = await getRefundById(id)
        setPayment(refundData)
        setPaymentVisibility(true)
      } catch (error) {
        addToast('Erro ao buscar detalhes do pagamento')
      }
    }
  }, [addToast, getPaymentById, getRefundById])

  const onRefundLinkShow = useCallback(async (id) => {
    try {
      const refundsData = await getRefundPaymentLinkById(id)
      setPaymentLabels({ detailTitle: 'Visualizar estorno', amountDueLabel: 'Valor estornado' })
      setPayment(refundsData)
      setPaymentVisibility(true)
      setPaymentLink(true)
    } catch (error) {
      addToast('Erro ao buscar detalhes do estorno do link de pagamento')
    }
  }, [addToast, getRefundPaymentLinkById])

  const closePaymentDetail = useCallback(() => {
    refetchTotalizers()

    setPaymentVisibility(false)
    setIsPayment(false)
    setPaymentLink(false)
    setIsEditing(false)
    setTransactionVisibility(false)
    setPayment({})
  }, [refetchTotalizers])

  const onPaymentCreation = useCallback(() => {
    setIsPayment(true)
    openTransactionForm(CREATE_PAYMENT_STATE)
  }, [openTransactionForm])

  const onTicketsRefundCreation = useCallback(async (transactionState, reservationPaymentId) => {
    const tickets = await getTicketsToRefund(reservationPaymentId)
    const method = transactionState === 'createRefundSplit' && 'pay_credit'
    const data = {
      id: reservationPaymentId,
      method: method || '',
      tickets
    }
    openTransactionForm(transactionState, data)
  }, [getTicketsToRefund, openTransactionForm])

  const onRefundSplitCreation = useCallback(
    (reservationPaymentId) => onTicketsRefundCreation(CREATE_REFUND_SPLIT_STATE, reservationPaymentId),
    [onTicketsRefundCreation]
  )

  const onRefundCreation = useCallback(
    () => onTicketsRefundCreation(CREATE_REFUND_STATE),
    [onTicketsRefundCreation]
  )

  const onLinkCreation = useCallback(async () => {
    refetchTotalizers()
    setPaymentLink(true)
    openTransactionForm(CREATE_LINK_STATE)
  }, [openTransactionForm, refetchTotalizers])

  const onLinkMultipontoCreation = useCallback(async () => {
    refetchTotalizers()
    const isSubordinates = await getSubordinates()
    isSubordinates ? openTransactionForm(CREATE_LINK_MULTIPONTO_STATE) : toggleConfirmActionVisible()
  }, [getSubordinates, openTransactionForm, refetchTotalizers])

  const onLinkPixCreation = useCallback(async () => {
    refetchTotalizers()
    openTransactionForm(CREATE_LINK_PIX_STATE)
  }, [openTransactionForm, refetchTotalizers])

  const onPaymentEdit = useCallback(async (id) => {
    setIsEditing(true)
    const payment = await getPaymentById(id)
    setTotalValue(payment?.value)
    openTransactionForm(EDIT_PAYMENT_STATE, payment)
  }, [getPaymentById, openTransactionForm])

  const onRefundEdit = useCallback(async (id) => {
    setIsEditing(true)
    const refund = await getRefundById(id)
    setTotalValue(refund?.value)
    openTransactionForm(EDIT_REFUND_STATE, refund)
  }, [getRefundById, openTransactionForm])

  const linkPaymentAlly = useCallback(
    id => `${REACT_APP_ALLY_URL}/checkout/flux/${id}`,
    [REACT_APP_ALLY_URL]
  )

  const linkPaymentNewCheckout = useCallback(
    id => `${REACT_APP_CHECKOUT_URL}/checkout/${id}`,
    [REACT_APP_CHECKOUT_URL]
  )

  const onCopyLink = useCallback((id, isGatewayPagarMe = false) => {
    const paymentLinkUrl = isGatewayPagarMe ? linkPaymentNewCheckout(id) : linkPaymentAlly(id)
    navigator.clipboard.writeText(paymentLinkUrl)
    addToast('Link copiado', 'success')
  }, [addToast, linkPaymentAlly, linkPaymentNewCheckout])

  const onRedirectLinkToPay = useCallback((id, isGatewayPagarMe = false) => {
    const paymentLinkUrl = isGatewayPagarMe ? linkPaymentNewCheckout(id) : linkPaymentAlly(id)
    id && window.open(paymentLinkUrl, '_blank', 'noopener noreferrer')
  }, [linkPaymentAlly, linkPaymentNewCheckout])

  const isLinkPaid = useCallback(
    ({ type, state }) => type !== 'Link' || state !== 'paid',
    []
  )

  const { allowed: canCreateMultiponto } = usePermission({
    permission: 'multiponto_payment_link',
    action: 'create'
  })

  const ticketsUpdatedInfos = useMemo(
    () => tickets.map(ticket => {
      const ticketSentLinksValue = transactionsTotalizers?.sentLinksByTicket?.[ticket.id] || 0
      const remainingTicketValue = (ticket.ticketPaymentBalance?.[0]?.remainingTicketValue || 0) - ticketSentLinksValue

      return {
        ...ticket,
        sentLinksValue: ticketSentLinksValue,
        ticketPaymentBalance: [
          {
            ...ticket.ticketPaymentBalance?.[0],
            remainingTicketValue
          }
        ]
      }
    }).filter(({ sentLinksValue }) => isPaymentLink ? !sentLinksValue : true),
    [isPaymentLink, tickets, transactionsTotalizers?.sentLinksByTicket]
  )

  const sortedTickets = useMemo(
    () => ticketsUpdatedInfos?.sort((ticketA, ticketB) => ticketA?.activity?.localeCompare(ticketB?.activity)),
    [ticketsUpdatedInfos]
  )

  const [transactionToConfirm, setTransactionToConfirm] = useState(null)
  const clearTransactionConfirmation = useCallback(() => setTransactionToConfirm(null), [])

  const onTransactionSubmitIntent = useCallback(
    (allowsOverpayment = false, hadDialogConfirmation = false) => (transaction) => {
      if (hadDialogConfirmation) {
        onTransactionSubmit(transactionToConfirm)
        clearTransactionConfirmation()
      } else {
        if (!isRefund) {
          const overpayingTickets = transaction?.ticketsIds?.filter(
            ({ ticketPaymentBalance, amount }) => amount > head(ticketPaymentBalance)?.remainingTicketValue
          )
          if (overpayingTickets?.length > 0 && !hadDialogConfirmation) {
            if (!allowsOverpayment || hasFiscalDuty) {
              return addToast(`Não é possível registrar um pagamento maior do que o valor do ingresso${hasFiscalDuty ? ' (Nota fiscal já emitida)' : ''}`)
            }
            return setTransactionToConfirm({
              ...transaction,
              overpayingTickets
            })
          }
        }
        onTransactionSubmit(transaction)
      }
    },
    [addToast, clearTransactionConfirmation, hasFiscalDuty, isRefund, onTransactionSubmit, transactionToConfirm]
  )

  const [isFiscalDutyBlockedDialogVisible, setIsBlockedFiscalDutyDialogVisible] = useState(false)
  const closeFiscalDutyBlockedDialog = useCallback(() => setIsBlockedFiscalDutyDialogVisible(false), [])

  const onDeletePrompt = useCallback((...paramsA) => (...paramsB) => {
    if (hasFiscalDuty) {
      return setIsBlockedFiscalDutyDialogVisible(true)
    }
    openDeleteDialog(...paramsA)(...paramsB)
  }, [hasFiscalDuty, openDeleteDialog])

  useEffect(() => {
    if (view?.isVisible && !isTransactionVisible) {
      setTransactionVisibility(true)
    }
  }, [view?.isVisible, isTransactionVisible])

  useEffect(() => {
    if (!view?.isVisible && !Object.entries(payment || {})?.length) {
      if (isPaymentVisible || isTransactionVisible) {
        closePaymentDetail()
      }
    }
  }, [closePaymentDetail, isPaymentVisible, isTransactionVisible, payment, view?.isVisible])

  const onPaymentDelete = onDeletePrompt('payment')
  const onRefundDelete = onDeletePrompt('refund')

  const mayRefund = !!amount.credit

  const contextData = useMemo(() => ({
    onPaymentShow,
    onPaymentLinkShow,
    onLinkCreation,
    canCreateMultiponto,
    onLinkMultipontoCreation,
    onLinkPixCreation,
    onPaymentCreation,
    onRefundCreation,
    onCopyLink,
    onRedirectLinkToPay,
    onPaymentEdit,
    onRefundEdit,
    onRefundLinkShow,
    onPaymentDelete,
    onRefundDelete,
    onRefundSplitCreation,
    isLinkPaid,
    mayRefund,
    amount
  }), [amount, canCreateMultiponto, isLinkPaid, mayRefund, onCopyLink, onLinkCreation, onLinkMultipontoCreation, onLinkPixCreation, onPaymentCreation, onPaymentDelete, onPaymentEdit, onPaymentLinkShow, onPaymentShow, onRedirectLinkToPay, onRefundCreation, onRefundDelete, onRefundEdit, onRefundLinkShow, onRefundSplitCreation])

  return (
    <FinanceActionsContext.Provider value={contextData}>
      <Dialog
        customContainercss={[fiscalDutyDialog]}
        isVisible={isFiscalDutyBlockedDialogVisible}
        onClose={closeFiscalDutyBlockedDialog}
        title='Reserva com NF emitida!'
      >
        <p>Não é possível excluir pagamentos e reembolsos pois a reserva possui nota fiscal emitida.</p>
        <GhostPrimaryButton onClick={closeFiscalDutyBlockedDialog}>Entendido</GhostPrimaryButton>
      </Dialog>

      <ConfirmDialog
        title='Pagamento em excesso'
        message={<>
          <p>Você está prestes a registrar pelo menos um pagamento maior do que o valor do ingresso:</p>
          <OverpaymentDetails overpayingTickets={transactionToConfirm?.overpayingTickets} />
          <p>Deseja continuar?</p>
        </>}
        buttonLabelConfirm='Continuar'
        isVisible={Boolean(transactionToConfirm?.overpayingTickets?.length)}
        successCallback={onTransactionSubmitIntent(true, true)}
        cancelCallback={clearTransactionConfirmation}
        customContainercss={[overpayingDialogCss]}
      />

      <ConfirmDialog
        title='Configuração Necessária'
        message='Você precisa configurar o seu cadastro no Binamik Pay para gerar um link de pagamento.'
        buttonLabelConfirm='Configurar'
        isVisible={isConfirmActionVisible}
        successCallback={() => {
          window.location.href = `${REACT_APP_PAY_URL}/configuration`
        }}
        cancelCallback={toggleConfirmActionHidden}
      />

      <Modal isVisible={isPaymentVisible} title={paymentLabels?.detailTitle} onCloseClick={closePaymentDetail}>
        <ReservationPaymentDetail
          labels='pagamento'
          detail={payment}
          amountDueValueLabel={paymentLabels?.amountDueLabel}
          onExpireLink={expirePaymentLink}
          setPaymentVisibility={closePaymentDetail}
          isPayment={isPayment}
          isPaymentLink={isPaymentLink}
          isPaymentShow={isPaymentShow}
        />
      </Modal>

      <TransactionModal
        view={view}
        formData={transactionFormData}
        formSchema={formSchema}
        tickets={sortedTickets}
        onCloseClick={closeTransactionForm}
        onFormSuccess={onTransactionSubmitIntent(allowTicketOverpayment)}
        loading={loading}
        transactionFormLoading={transactionFormLoading}
        isLinkCreateLoading={isLinkCreateLoading}
        setTicketsIds={setTicketsIds}
        ticketsIds={ticketsIds}
        paymentTotalValue={(isPaymentEdit || isRefundEdit) ? totalValue : paymentTotalValue}
        isPayment={isPayment}
        isEditing={isEditing}
        hasFiscalDuty={hasFiscalDuty}
      />

      <PromptDialog {...promptProps} />

      {children}
    </FinanceActionsContext.Provider>
  )
}
